import Component from '../core/Component'
import { queryAll } from '../utils/dom'

export const STATES = {
    LOADING: 'is-loading',
    SAVED: 'is-saved'
}

export default class PageEdit extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            submit: null
        }
    }

    prepare() {
        document.addEventListener('click', event => {
            if (event.target.closest('a:not(.is-system-link)') || event.target.closest('button:not(.is-system-button)')) {
                event.preventDefault()
            }
        })

        document.addEventListener('focusin', this.handleFocus)

        this.ref.submit.addEventListener('click', this.handleClick)
        this.initEditor()
    }

    initEditor() {
        Array.from(document.querySelectorAll('[data-key]'))
            .filter(element => {
                const tag = element.parentNode.tagName.toLowerCase()
                element.addEventListener('click', () => {
                    this.ref.submit.classList.remove(STATES.LOADING)
                    this.ref.submit.classList.remove(STATES.SAVED)
                })
                return tag == 'div'
            })
            .forEach(element => CKEDITOR.inline(element))
    }

    handleFocus = event => {
        const target = event.target.closest('[contenteditable="true"]')

        if (!target) {
            return
        }

        const key = target.dataset.key

        const elements = queryAll(`[data-key="${target.dataset.key}"]`)

        if (elements.length < 2) {
            return
        }

        this.attachGroupEditing(target, elements.filter(element => element !== target))
    }

    handleClick = event => {
        event.preventDefault()

        const fields = Array.from(document.querySelectorAll('[data-key]'))
            .reduce((acc, element) => {
                acc[element.dataset.key] = element.innerHTML
                return acc
            }, {})

        this.ref.submit.classList.add(STATES.LOADING)
        this.ref.submit.classList.remove(STATES.SAVED)

        this.send(fields)
    }

    attachGroupEditing(master, slaves) {
        const edit = event => {
            slaves.forEach(slave => slave.innerHTML = master.innerHTML)
        }

        const blur = event => {
            master.removeEventListener('input', edit)
            master.removeEventListener('blur', blur)
        }

        master.addEventListener('input', edit)
        master.addEventListener('blur', blur)
    }

    send(fields) {
        var xhr = new XMLHttpRequest()
        var submit = this.ref.submit
        xhr.open("POST", this.element.dataset.url, true)
        xhr.setRequestHeader('Content-Type', 'application/json')

        xhr.onreadystatechange = function() { // Call a function when the state changes.
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                submit.classList.remove(STATES.LOADING)
                submit.classList.add(STATES.SAVED)
            }
        }
        xhr.send(JSON.stringify({fields}));
    }
}