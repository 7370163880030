import Component from '../core/Component'

export default class FormNewsletter extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            error: null,
            confirmation: null
        }
    }

    prepare() {
        this.element.addEventListener('nette:invalid', ::this.handleInvalid)
        this.element.addEventListener('nette:valid', ::this.handleValid)

        if (this.ref.confirmation) {
            const modalNewsletter = document.getElementById('modal-newsletter')

            if (modalNewsletter) {
                const modal = Component.getFromElement(modalNewsletter)

                if (modal) {
                    modal.open()
                }
            }
        }
    }

    handleInvalid(event) {
        event.preventDefault()
        event.stopPropagation()

        const element = event.target

        const errorElement = document.createElement('div')
        errorElement.className = 'InputError'

        errorElement.innerHTML = element.getAttribute('data-nette-error')
        this.ref.error.innerHTML = ''
        this.ref.error.appendChild(errorElement)
        element.classList.add('has-error')
        element.classList.remove('is-valid')

    }

    handleValid(event) {
        event.preventDefault()
        event.stopPropagation()
        const element = event.target

        if (element.tagName.toLowerCase() != 'button') {
            this.ref.error.innerHTML = ''
        }

        element.classList.remove('has-error')
        element.classList.add('is-valid')

    }
}