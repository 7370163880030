import Component from '../core/Component'
import support from '../utils/BrowserSupport'
import { toggleClass } from '../utils/dom'
import EventBus from '../core/EventBus'
export const STATES = {
    CURRENT: 'is-current',
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}
const defaults = {
    delay: 5000
}
export default class Slider extends Component {
    constructor(element) {
        super(element, defaults)
        this.ref = {
            slides: [],
            controls: []
        }
        this.current = 0
        this.timer = null
        this.isPlaying = false
    }
    prepare() {
        if (this.ref.slides.length < 2) {
            return
        }
        this.ref.slides.forEach((element, index) => {
            element.addEventListener(support.transitionEnd, () => {
                if (element.classList.contains(STATES.CURRENT)) {
                    this.commit(index)
                }
            })
        })
        this.ref.controls.forEach((element, index) => element.addEventListener('click', () => this.show(index)))
        this.element.addEventListener('mouseenter', this.handleMouseEnter)
        this.element.addEventListener('mouseleave', this.handleMouseLeave)
        this.start()
    }
    handleMouseEnter = () => {
        this.stop()
    }
    handleMouseLeave = () => {
        this.start()
    }
    commit(next) {
        this.ref.slides[this.current].classList.remove(STATES.ACTIVE)
        this.ref.slides[this.current].classList.remove(STATES.VISIBLE)
        this.ref.slides[next].classList.remove(STATES.CURRENT)
        this.current = next
        if (this.isPlaying) {
            this.queueUp()
        }
    }
    show(index) {
        const slide = this.ref.slides[index]
        slide.classList.add(STATES.VISIBLE)
        slide.classList.add(STATES.CURRENT)
        slide.offsetWidth
        slide.classList.add(STATES.ACTIVE)
        this.ref.controls.forEach((element, i) => toggleClass(element, STATES.ACTIVE, i === index))
        EventBus.emit('slide:change', {
            type: slide.dataset.type
        })
    }
    next = () => {
        this.show((this.current + 1) % this.ref.slides.length)
    }
    start() {
        clearTimeout(this.timer)
        this.isPlaying = true
        this.queueUp()
    }
    stop() {
        this.isPlaying = false
        clearTimeout(this.timer)
    }
    queueUp() {
        clearTimeout(this.timer)
        this.timer = setTimeout(this.next, this.getDelay(this.ref.slides[this.current]))
    }
    getCurrentType() {
        return this.ref.slides[this.current].dataset.type
    }
    getDelay(slide) {
        let delay = parseInt(slide.dataset.timer, 10)
        if (isNaN(delay)) {
            delay = this.options.delay
        }
        return delay
    }
}