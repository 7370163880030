import Component from '../core/Component'
import { substitute } from '../utils/String'
import { toggleClass } from '../utils/dom'

export const STATES = {
    FILES: 'has-files'
}

export default class InputUpload extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            inner: null,
            list: null,
            itemTemplate: null,
            fileTemplate: null
        }

        this.fileNames = []
        this.counter = 0
    }

    prepare() {
        this.itemTemplate = this.ref.itemTemplate.textContent.trim()
        this.fileTemplate = this.ref.fileTemplate.textContent.trim()
        this.element.addEventListener('change', this.handleChange)
    }

    handleChange = event => {
        this.appendInputToList(event.target)
        this.createNewInput()
    }

    createNewInput() {
        const div = document.createElement('div')
        div.innerHTML = substitute(this.fileTemplate, {id: `file-${this.fileNames.length}`})

        this.ref.inner.prepend(div.firstChild)
    }

    removeItemFromList(element) {
        this.counter--
        element.parentNode.removeChild(element)
        this.check()
    }

    appendInputToList(input) {
        const files = [...input.files]

        if (files.length < 1) {
            return
        }
        const fileName = files[0].name

        const itemElement = document.createElement('div')
        itemElement.classList.add('InputUpload-item')

        itemElement.innerHTML = substitute(this.itemTemplate, { fileName })

        input.tabIndex = '-1'
        itemElement.prepend(input)

        itemElement.querySelector('[data-ref="buttonRemove"]').addEventListener('click', () => this.removeItemFromList(itemElement))

        this.ref.list.appendChild(itemElement)
        this.fileNames.push(fileName)
        this.counter++
        this.check()
    }

    check() {
        toggleClass(this.element, STATES.FILES, this.counter > 0)
    }
}