import Component from '../core/Component';
import Swup from 'swup';

export default class Animation extends Component {
    constructor(element) {
        super(element);

        // this.ref = {
        //    test: null,
        //    items: [],
        // }
    }

    // async require() {
    //    this.throttle = await import('lodash/throttle');
    // }

    prepare() {
        let options = {scroll:false, cache:false};
        const swupp = new Swup(options);
    }
}