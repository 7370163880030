import Component from '../core/Component'

export default class TruckParkingVideo extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            video: null,
            source: null
        }
    }

    prepare() {
        if(window.matchMedia('(min-width: 48rem)').matches) {
            this.ref.source.setAttribute('src', '/files/hero-parking.mp4')
        }
        else {
            this.ref.source.setAttribute('src', '/files/hero-parking-mobile.mp4')
        }
        this.ref.video.load()
    }
}
