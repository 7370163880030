import Component from '../core/Component'
import GoogleMapsLoader from 'google-maps'
import throttle from 'lodash/throttle'
import { GOOGLE_MAPS_API_KEY } from '../core/config'
import { createMarker } from './Marker'

GoogleMapsLoader.KEY = GOOGLE_MAPS_API_KEY

export const defaults = {
    zoom: 13,
    lat: '50.088470',
    lng: '14.418742'
}

export default class ContactMap extends Component {
    constructor(element) {
        super(element)

        this.latlng = null
        this.isReady = false

        this.ref = {
            markerTemplate: null
        }

        let options = {}

        if (this.element.dataset.options) {
            options = JSON.parse(this.element.dataset.options)
        }

        this.options = {
            ...defaults,
            ...options
        }

        this.markerTemplate = ''
        if (this.ref.markerTemplate) {
            this.markerTemplate = this.ref.markerTemplate.textContent.trim()
            this.ref.markerTemplate.parentNode.removeChild(this.ref.markerTemplate)
            this.ref.markerTemplate = null
        }


        this.handleResize = throttle(::this.handleResize, 200)
    }

    prepare() {
        GoogleMapsLoader.load(::this.render)
        window.addEventListener('resize', this.handleResize)
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
        this.map = null
    }

    handleResize() {
        this.center()
    }

    render(google) {
        const latlng = new google.maps.LatLng(this.options.lat, this.options.lng)
        this.google = google
        this.latlng = latlng

        this.map = new google.maps.Map(this.element, {
            center: latlng,
            zoom: this.options.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: this.options.styles,
            disableDefaultUI: true
        })

        this.setMarker()

        setTimeout(() => {
            this.isReady = true
            this.center()
        }, 0)
    }

    setMarker() {
        const Marker = createMarker(this.google)

        const pin = new Marker({
            position: this.latlng,
            map: this.map,
            template: this.markerTemplate,
            className: 'contact-Marker'
        })
    }

    center() {
        if (this.isReady) {
            this.google.maps.event.trigger(this.map,'resize')
            this.map.setCenter(this.latlng)
        }
    }
}