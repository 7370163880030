const special = {
    'a': /[àáâãäåăą]/g,
    'A': /[ÀÁÂÃÄÅĂĄ]/g,
    'c': /[ćčç]/g,
    'C': /[ĆČÇ]/g,
    'd': /[ďđ]/g,
    'D': /[ĎÐ]/g,
    'e': /[èéêëěę]/g,
    'E': /[ÈÉÊËĚĘ]/g,
    'g': /[ğ]/g,
    'G': /[Ğ]/g,
    'i': /[ìíîï]/g,
    'I': /[ÌÍÎÏ]/g,
    'l': /[ĺľł]/g,
    'L': /[ĹĽŁ]/g,
    'n': /[ñňń]/g,
    'N': /[ÑŇŃ]/g,
    'o': /[òóôõöøő]/g,
    'O': /[ÒÓÔÕÖØ]/g,
    'r': /[řŕ]/g,
    'R': /[ŘŔ]/g,
    's': /[ššş]/g,
    'S': /[ŠŞŚ]/g,
    't': /[ťţ]/g,
    'T': /[ŤŢ]/g,
    'u': /[ùúûůüµ]/g,
    'U': /[ÙÚÛŮÜ]/g,
    'y': /[ÿý]/g,
    'Y': /[ŸÝ]/g,
    'z': /[žźż]/g,
    'Z': /[ŽŹŻ]/g,
    'th': /[þ]/g,
    'TH': /[Þ]/g,
    'dh': /[ð]/g,
    'DH': /[Ð]/g,
    'ss': /[ß]/g,
    'oe': /[œ]/g,
    'OE': /[Œ]/g,
    'ae': /[æ]/g,
    'AE': /[Æ]/g
}

function walk(string, replacements){
    let result = string
    for (let key in replacements) {
        result = result.replace(replacements[key], key)
    }
    return result
}

export function standardize(str) {
    return walk(str, special)
}

export function substitute(str, object, preserve = false, regexp){
    return String(str).replace(regexp || (/\\?\{([^{}]+)\}/g), function(match, name){
        if (match.charAt(0) == '\\') return match.slice(1);
        return (object[name] != null) ? object[name] : (preserve ? '{' + name + '}' : '');
    });
}